export const initPagination = (dataSource, pagination)=>{
	pagination.current = 0;
	pagination.dataSource = dataSource;
	pagination.pageCount = Math.ceil(dataSource.length / pagination.pageSize);
	
	return pagination.dataSource.slice(0, pagination.pageSize);
};

export const loadPageData = (showData, pagination)=>{
	pagination.current++;
	if(pagination.current < pagination.pageCount){
		return showData.concat(
			pagination.dataSource.slice(pagination.current * pagination.pageSize, 
			(pagination.current + 1) * pagination.pageSize < pagination.dataSource.length ? 
			(pagination.current + 1) * pagination.pageSize : 
			pagination.dataSource.length));
	}
	
	return showData;
};